<template>
  <div class="page-wrapper">
    <div class="flex space-between mb-1 mb-4">
      <h1 class="page-title">
        {{ title }}
      </h1>
      <slot name="actions"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
      default: ''
    }
  }
}
</script>
