import { trancateString } from '@/utils/text-formatter'
import config from "@/utils/appConfig";

export const calcSourcesRiskPercent = (sources) =>
    sources.reduce((acc, curr) => {
        if (curr.funds.score >= Number(config.VUE_APP_RISK_SCORE_TRESHOLD)) {
            acc += curr.share
        }

        return acc
    }, 0)


export const formatFunds = (fund, withPercent = true) => {
    const formatted = Math.ceil(fund)

    if (withPercent) {
        return formatted === '0.00' ? '< 0.01%' : formatted + '%'
    } else {
        return formatted === '0.00' ? '< 0.01' : formatted
    }
}

export const getOwnerByType = (data) => {
    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'unidentified service / exchange') {
        return data.cluster || data.owner
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'small transactions') {
        return 'Multiple'
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'unknown single wallet service') {
        return data.address
            ? {
                owner: trancateString(data.address, 8),
                value: data.address,
                isLink: true
            }
            : 'Multiple'
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'unknown wallet / otc / service') {
        return data.address
            ? {
                owner: trancateString(data.address, 8),
                value: data.address,
                isLink: true
            }
            : data.owner
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'maximum depth reached' || data.funds && data.funds.name && data.funds.name.toLowerCase() === 'small transactions') {
        return 'Multiple'
    }

    return data.owner || '--'
}

export const checkMultiple = (val) => {
    return val === null || val === 'Multiple'
}

export const ownerLabelFormatter = (addressData) => {
    if (!addressData) return 'Not identified'
    if (addressData.owner || (addressData.clusterData && addressData.clusterData.owner)) {
        if (addressData.owner === addressData.clusterData && addressData.clusterData.owner) {
            return addressData.owner
        }

        if (addressData.owner && addressData.clusterData && addressData.clusterData.owner) {
            return `${addressData.owner}, ${addressData.clusterData.owner}`
        }

        if (addressData.clusterData && addressData.clusterData.owner) {
            return addressData.clusterData.owner
        }

        if (addressData.owner) {
            return addressData.owner
        }
    }

    return 'Not identified'
}

export const isOwnerExist = (addressData) => {
    if (!addressData) return false
    if (addressData.owner ||
        addressData.description ||
        addressData.tags && addressData.tags.length || 
        addressData.type) return true
    return false
}

export const isClusterExist = (addressData) => {
    if (!addressData && !addressData.clusterData) return false
    if (addressData.clusterData &&
        addressData.clusterData?.description ||
        addressData.clusterData?.owner ||
        addressData.clusterData?.tags && addressData.clusterData?.tags.length || 
        addressData.clusterData?.type) return true
    return false
}
