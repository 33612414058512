<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    full-submit
    overflowing
    ok-only
    submit-title="Got it"
    title="Cluster Labels Uploaded"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('close')"
  >
    <div class="mb-4">
      <strong class="word-break">{{ fileName }}</strong>
    </div>
    <div class="flex align-center mb-3 fs-14">
      <gl-icon
        class="mr-3"
        :height="24"
        name="upload-label"
        :width="24"
      />
      <div>
        {{ data.inserted }} clusters are labeled
      </div>
    </div>
    <div class="flex align-center mb-3 fs-14">
      <gl-icon
        class="mr-3"
        :height="24"
        name="tagging-dark"
        :width="24"
      />
      <div>
        {{ data.totalTags }} tags added
      </div>
    </div>
    <div class="flex align-center mb-3 fs-14">
      <gl-icon
        class="mr-3"
        :height="24"
        name="tagging-dark"
        :width="24"
      />
      <div>
        {{ data.newTags && data.newTags.length }} new tags created
      </div>
    </div>
    <div class="flex align-center mb-3 fs-14">
      <gl-icon
        class="mr-3"
        :height="24"
        name="upload-types"
        :width="24"
      />
      <div>
        {{ data.newTypes && data.newTypes.length }} new types created
      </div>
    </div>
    <div class="flex align-end mb-3 fs-14">
      <gl-icon
        class="mr-3"
        :height="24"
        name="def-report"
        :width="24"
      />
      <div>
        {{ data.duplicates }} cluster entries skipped as duplicates
      </div>
    </div>
    <div class="flex align-center mb-3 fs-14">
      <gl-icon
        class="mr-3"
        :height="24"
        name="def-report"
        :width="24"
      />
      <div>
        {{ data.conflicted }} conflicts
      </div>
    </div>
    <div class="flex align-center warning-text mb-3 fs-14">
      <gl-icon
        class="mr-3"
        :height="24"
        name="warn-report"
        :width="24"
      />
      <div>
        {{ data.notfound }} address/cluster is invalid
      </div>
    </div>
<!--    <div class="flex align-center warning-text mb-4 fs-14">-->
<!--      <gl-icon-->
<!--        class="mr-3"-->
<!--        :height="24"-->
<!--        name="warn-report"-->
<!--        :width="24"-->
<!--      />-->
<!--      <div>-->
<!--        {{ data.lacks }} address lacks mandatory fields-->
<!--      </div>-->
<!--    </div>-->
    <button
      v-if="data.log && data.log.length > 0"
      class="gl-button gl-button--light gl-button--full gl-button--padder fs-14 mb-4"
      @click="csvExport(data.log, 'cluster-logs')"
    >
      Download results
    </button>
    <button
      v-if="data.conflicted > 0"
      class="gl-button gl-button--light gl-button--full gl-button--padder fs-14 mb-4"
      @click="$router.push({ path: 'labeling', query: { tab: 'cluster-conflicts' } })"
    >
      go to conflicts
    </button>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlIcon from '@/components/gl-icon'
// Utils
import { csvExport } from '@/utils/export-data'

export default {
  components: {
    GlModal,
    GlIcon,
  },
  inheritAttrs: false,
  props: {
    fileName :{
      type: String,
      default: 'index.csv',
    },
    data :{
      type: Object,
      default: () => ({})
    },
  },
  methods: {
    csvExport,
  },
}
</script>
