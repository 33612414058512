<template>
  <div>
    <div
      v-if="list && list.length === 0 && !loading"
      class="cluster-conflicts-empty"
    >
      No unresolved conflicts
    </div>
    <GlLoader
      v-else-if="loading"
      class="cluster-conflicts-empty"
    />
    <div v-else>
      <div
        v-for="(item, index) in list"
        :key="index"
        class="cluster-conflict mb-4"
      >
        <div class="cluster-conflict__exchange">
          <div class="flex-1 mr-4">
            <div class="flex space-between mb-4">
              <InfoBlock
                class="flex-1"
                label="Cluster ID"
                :value="item.fromCluster.cluster"
              />
              <InfoBlock
                class="flex-1"
                label="Cluster size"
                :value="item.fromCluster.addressCount"
              />
              <div class="flex-1" />
            </div>
            <div class="flex">
              <InfoBlock
                class="flex-1"
                label="Owner"
                :value="capitalizeFirstLetter(item.fromCluster.owner)"
              />
              <div class="flex-1">
                <div class="tagging__key mb-2">
                  Type
                </div>
                <div v-if="item.fromCluster.types[0]">
                  <GlTag
                    v-for="(tag, i) in item.fromCluster.types"
                    :key="i"
                    class="mr-1 mb-1"
                    :score="tag.score"
                    :tag="capitalizeFirstLetter(tag.name)"
                  />
                </div>
                <div v-else>
                  --
                </div>
              </div>
              <div class="flex-1">
                <div class="tagging__key mb-2">
                  Tags
                </div>
                <div v-if="item.fromCluster.tags && item.fromCluster.tags.length > 0">
                  <GlTag
                    v-for="(tag, i) in item.fromCluster.tags"
                    :key="i"
                    class="mr-1 mb-1"
                    :score="tag.score"
                    :tag="capitalizeFirstLetter(tag.name)"
                  />
                </div>
                <div v-else>
                  --
                </div>
              </div>
            </div>
          </div>
          <gl-icon
            v-if="item.toCluster"
            class="ml-3 mr-3"
            :height="24"
            name="many-to-one"
            :width="24"
          />
          <div
            v-if="item.toCluster"
            class="flex-1 ml-4"
          >
            <div class="flex space-between mb-4">
              <InfoBlock
                class="flex-1"
                label="Cluster ID"
                :value="item.toCluster.cluster"
              />
              <InfoBlock
                class="flex-1"
                label="Cluster size"
                :value="item.toCluster.addressCount"
              />
              <div class="flex-1" />
            </div>
            <div class="flex">
              <InfoBlock
                class="flex-1"
                label="Owner"
                :value="capitalizeFirstLetter(item.toCluster.owner)"
              />
              <div class="flex-1">
                <div class="tagging__key mb-2">
                  Type
                </div>
                <div v-if="item.toCluster.types[0]">
                  <GlTag
                    v-for="(tag, i) in item.toCluster.types"
                    :key="i"
                    class="mr-1 mb-1"
                    :score="tag.score"
                    :tag="capitalizeFirstLetter(tag.name)"
                  />
                </div>
                <div v-else>
                  --
                </div>
              </div>
              <div class="flex-1">
                <div class="tagging__key mb-2">
                  Tags
                </div>
                <div v-if="item.toCluster.tags && item.toCluster.tags.length > 0">
                  <GlTag
                    v-for="(tag, i) in item.toCluster.tags"
                    :key="i"
                    class="mr-1 mb-1"
                    :score="tag.score"
                    :tag="capitalizeFirstLetter(tag.name)"
                  />
                </div>
                <div v-else>
                  --
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-2 mb-2 grey-hr">
        <div class="flex space-between">
          <div class="flex-1 flex">
            <div class="mr-4">
              <div class="tagging__key mb-2">
                Merged at transaction
              </div>
              <div class="flex align-center">
                {{ trancateString(item.txHash, 6) }}
                <gl-menu-item
                  class="sidebar__history-copy"
                  icon="copy"
                  :icon-height="24"
                  :icon-width="24"
                  @click="copy(item.txHash)"
                />
              </div>
            </div>
            <InfoBlock
              class="mr-4"
              label="Block"
              :value="item.blockHeight"
            />
            <InfoBlock
              label="Date & Time"
              :value="formatDate(item.timestamp * 1000, 'dd.MM.yyyy, HH:mm')"
            />
          </div>
          <div class="flex-1 flex flex-end align-center">
            <gl-menu-item
              class="sidebar__history-copy mr-3"
              icon="delete"
              :icon-height="24"
              :icon-width="24"
              label="Resolve"
              warn
              @click="resolveMerge(item.fromCluster.cluster)"
            />
            <button
              class="gl-button gl-button--dark gl-button--padder"
              @click="editLabel(item)"
            >
              Edit Label
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="list && list.length > 0 && !loading"
      class="flex space-between pa-1 cluster-conflicts__pagination-wrap"
    >
      <div class="flex align-center pa-3">
        <div class="mr-2 fs-14 bold">
          Rows per page:
        </div>
        <vSelect
          v-model="perPage"
          class="stat-select__pagination mr-1"
          :clearable="false"
          :options="pagesOptions"
          @input="countChange"
        />
      </div>

      <o-pagination
        v-if="list.length"
        class="stat-pagination"
        :current.sync="currentPage"
        order="right"
        :per-page="perPage"
        simple
        :total="total"
        @change="pageChange"
      >
        <o-pagination-button
          slot="previous"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page mr-2"
            :disabled="props.page.disabled"
            icon="left"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>

        <o-pagination-button
          slot="next"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page"
            :disabled="props.page.disabled"
            icon="right"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>
      </o-pagination>
    </div>
  </div>
</template>

<script>
// Components
import vSelect from 'vue-select';
import GlTag from '@/components/gl-tag';
import GlIcon from '@/components/gl-icon';
import GlLoader from "@/components/gl-loader";
import GlMenuItem from '@/components/gl-menu-item';
import InfoBlock from '../../../components/gl-info-block';
// Utils
import { formatDate } from "@/utils/format-date";
import { capitalizeFirstLetter, trancateString } from "@/utils/text-formatter";
// Vuex
import { mapActions } from "vuex";

export default {
  name: 'ClusterConflict',
  components: {
    GlTag,
    GlIcon,
    vSelect,
    GlLoader,
    InfoBlock,
    GlMenuItem
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pagesOptions: [5, 10,20, 50, 100],
      perPage: 10,
      currentPage: 1,
    }
  },
  methods: {
    ...mapActions({
      resolveClusterConflicts: 'tagging/resolveClusterConflicts'
    }),
    capitalizeFirstLetter,
    formatDate,
    trancateString,
    resolveMerge(cluster) {
      this.resolveClusterConflicts(cluster).then(({ success }) => {
        if (success) {
          this.$emit('resolve')
        }
      })
    },
    pageChange(page) {
      this.currentPage = page
      this.$emit('paginate', this.currentPage, this.perPage)
    },
    countChange(count) {
      this.perPage = count
      this.$emit('paginate', this.currentPage, this.perPage)
    },
    async copy(value) {
      await navigator.clipboard.writeText(value).then(() => {
        this.$toasted.global.success({ message: 'Copied!' })
      })
    },
    editLabel(item) {
      this.$router.replace({
        name: 'labeling',
        query: {
          tab: `labeling`,
          search: item.fromCluster.cluster,
          cluster: item.fromCluster.cluster
        }
      }).catch(err => err)
    }
  }
}
</script>

<style>
.cluster-conflict {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  padding: 24px;
  background: var(--white);
}

.cluster-conflicts-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    color: var(--dark-grey);
    margin: auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}

.cluster-conflict__exchange {
  display: flex;
  align-items: center;
}

.cluster-conflicts__pagination-wrap {
  background: var(--white);
}
</style>
