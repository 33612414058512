import analyticsState from "@/store/modules/analytics/state"

export const formatHash = hash => {
	let startSubStr = hash.substring(0,7)
	let endSubStr = hash.slice(-7)

	return `${startSubStr}...${endSubStr}`
}

export const getDecimalVal = (addressData, amount) => {
	const defDecimal = analyticsState?.coinData?.decimals || 18
	const decimal = addressData
		&& addressData.contractData
		&& addressData.contractData.decimals

	return Number(amount) / Math.pow(10, decimal || defDecimal)
}

export const formatBtcAmount = (amount, withBtc = true, searchType = '', hasContracts = false, needDecimal = false) => {
	console.log(amount, withBtc, searchType, hasContracts, needDecimal)
	let contract = ''
	let del = 100000000
	let defFixedVal = 8
	let defDecimal = Math.pow(10, analyticsState?.coinData?.decimals || 18)

	const decimalsMap = {
		'trx': 1000000,
		'eth': 1000000000000000000,
		'btc': 1000000000000000000,
	}

	if (analyticsState.coinData.family === 'eth') {
		del = needDecimal ? decimalsMap[searchType] || defDecimal : 1

		// Contract
		if (hasContracts) {
			contract = hasContracts
		} else {
			contract = analyticsState.coinData.label
		}

		if (amount < 0.00000001 && !hasContracts) {
			contract = 'wei'
			defFixedVal = 0
			amount = amount * defDecimal
		}
	}

	if (!searchType) contract = analyticsState.coinData.label || 'BTC'
	if (!contract || searchType.toLowerCase() === contract.toLowerCase()) contract = analyticsState.coinData.label

	const formattedAmount = (fAmount, fDel, fixedVal) => {
		return Math.abs(fAmount) < 0.00000001 ? (fAmount / fDel).toFixed(Math.floor(Math.abs(Math.log10(fAmount)))) : (fAmount / fDel).toFixed(fixedVal)
	}

	return amount
		? `${analyticsState.coinData.family === 'eth'
			? formattedAmount(amount, del, defFixedVal)
			: formattedAmount(amount, del, defFixedVal)} ${withBtc
			? `${contract}`
			: ''}`
		: analyticsState.coinData.family === 'eth'
			? ''
			: 0
}
