<template>
  <div
    class="tag"
    :class="[{'tag__selected': isSelected}, {'tag__score--not-removable': score !== undefined && score !== null && !removable}]"
    :style="`border-color: ${findColorByTypeScore(score)}; background-color: ${hex2rgba(findColorByTypeScore(score), 0.25)}`"
    @click="selectTag"
  >
    <div
      v-if="score !== undefined && score !== null"
      class="mr-2 tag__score"
      :style="`background-color: ${findColorByTypeScore(score)};`"
    >
      {{ score }}
    </div>
    <div
      class="max-w-150 ellipsis"
      :class="[{'ml-1': score === undefined || score === null}]"
    >
      {{ tag }}
    </div>
    <gl-icon
      v-if="removable"
      class="tag__close-icon"
      :height="16"
      name="tag-close"
      :width="16"
      @click="remove"
    />
  </div>
</template>

<script>
//Components
import GlIcon from '@/components/gl-icon'
// Utils
import { findColorByTypeScore } from "@/utils/cytoskape-ui-rules";

export default {
  components: {
    GlIcon,
  },
  props: {
    tag: {
      type: String,
      default: '---',
      require: true,
    },
    score: {
      type: Number,
      default: null,
    },
    value: {
      type: [Number, String, Object],
      default: null
    },
    removable: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
       isSelected: false,
    }
  },
  methods: {
    findColorByTypeScore,
    selectTag() {
      this.isSelected = !this.isSelected
    },
    remove() {
      this.$emit('remove', this.value ? this.value : this.tag)
    },
    hex2rgba(hex, alpha = 1) {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    },
  },
}
</script>

<style scoped>
.tag__close-icon {
  /*margin-right: 2px;*/
  margin-top: 1px;
  float: right;
  cursor: pointer;
  min-width: auto;
}

.tag {
  display: inline-flex;
  white-space: nowrap;
  font-size: 14px;
  border-radius: 20px;
  align-items: center;
  font-weight: 500;
  padding: 1px;
  padding-right: 4px;
  border: 1px solid var(--reflex-bluet);
}

.tag__score {
  color: #fff;
  border-radius: 20px;
  font-size: 12px;
  padding: 2px;
  min-width: 19px;
  text-align: center;
}

.tag__score--not-removable {
  padding-right: 3px;
}

/*.tag__selected {*/
/*  border: 2px solid var(--reflex-bluet);*/
/*}*/
</style>
