<template>
  <GlPageWrap
    class="relative"
    title="Labeling"
  >
    <template #actions>
      <GlCoinSelect
        class="m-fullwidth"
        @change="handleChangeCoin"
      />
    </template>
    <div class="flex align-baseline space-between">
      <div
        ref="tabsWrapper"
        class="flex align-baseline space-between overflow-auto"
      >
        <div class="flex align-end space-between labeling-tabs-wrap mb-4 mt-3">
          <div class="flex mb-2 m-fullwidth">
            <h1
              class="tab mr-4 page-title-tab"
              :class="{'tab-active': activeTab === 'labeling'}"
              @click="handleActiveTab('labeling')"
            >
              Search and Label
            </h1>
            <h1
              class="tab page-title-tab mr-4"
              :class="{'tab-active': activeTab === 'bulk-labeling'}"
              @click="handleActiveTab('bulk-labeling')"
            >
              Bulk Labeling
            </h1>
            <h1
              class="tab mr-4 page-title-tab"
              :class="{'tab-active': activeTab === 'address-conflicts'}"
              @click="handleActiveTab('address-conflicts')"
            >
              Address Label Conflicts
              <div
                v-if="conflictCounter > 0"
                class="tab-counter"
              >
                {{ conflictCounter }}
              </div>
            </h1>
            <h1
              v-if="!$can('use', 'eth')"
              class="tab mr-4 page-title-tab"
              :class="{'tab-active': activeTab === 'cluster-conflicts'}"
              @click="handleActiveTab('cluster-conflicts')"
            >
              Cluster Label Conflicts
              <div
                v-if="conflictClusterCounter > 0"
                class="tab-counter"
              >
                {{ conflictClusterCounter }}
              </div>
            </h1>
            <h1
              v-if="!$can('use', 'eth')"
              class="tab page-title-tab"
              :class="{'tab-active': activeTab === 'cluster-merge-conflicts'}"
              @click="handleActiveTab('cluster-merge-conflicts')"
            >
              Cluster Conflicts
            </h1>
          </div>
        </div>
      </div>
      <div class="ml-4 m-pa-2 m-fixed-bottom-wrapper m-ml-0">
        <button
          v-show="activeTab === 'cluster-merge-conflicts'"
          class="gl-button gl-button--dark gl-button--padder mr-2 min-w-200 m-fullwidth"
          @click="enableCheckClusterConflicts"
        >
          Cluster Check
        </button>
      </div>
    </div>
   
    <div v-show="activeTab === 'cluster-merge-conflicts'">
      <ClusterConflicts
        :list="clusterConflictsList"
        :loading="loading"
        :total="clusterMergeConflictsTotal"
        @paginate="paginateClusterConflicts"
        @resolve="loadClusterConflicts"
      />
    </div>
    <div v-show="activeTab === 'address-conflicts'">
      <AddressConflictsTab
        :data="conflictData"
        :pagination="addressConflictsPagination"
        @address-apply="addressApply"
        @address-keep="addressKeep"
        @change-count="changeAdrConflictsCount"
        @change-page="changeAdrConflictsPage"
        @clear-filters="clearFilters"
        @searching="addressConflictsSearch"
        @set-date="setDateAddressConflicts"
      />
    </div>
    <div v-show="activeTab === 'bulk-labeling'">
      <div class="tagging__block flex m-column fit-content">
        <AddressBulk
          class="flex-1"
          :class="{'mr-4 m-mr-0 m-mb-3': !$can('use', 'eth')}"
          @complete-uploading="completeUpload"
          @error-uploading="completeUpload"
          @start-uploading="handleUploading"
        />
        <ClusterBulk
          v-if="!$can('use', 'eth')"
          class="flex-1"
          @complete-uploading="completeUpload"
          @error-uploading="completeUpload"
          @start-uploading="handleUploading"
        />
      </div>
    </div>
    <div v-show="activeTab === 'cluster-conflicts'">
      <ClustersConflictsTab
        :data="conflictData"
        :pagination="clustersConflictsPagination"
        @change-count="changeClusterConflictsCount"
        @change-page="changeClusterConflictsPage"
        @clear-filters="clearFilters"
        @cluster-apply="addressApply"
        @cluster-keep="addressKeep"
        @searching="clusterConflictsSearch"
        @set-date="setDateClusterConflicts"
      />
    </div>
    <div
      v-show="activeTab === 'labeling'"
      class="tagging"
    >
      <div class="flex-2 mr-4 fullwidth m-mr-0 t-mr-0">
        <div class="tagging__block">
          <gl-search-box
            v-model="search"
            button-text="Search"
            class="mb-4"
            dark-clear
            :disabled="!search || loading"
            grey
            placeholder="Enter the address or cluster id to start labeling"
            tagging
            @clear="resetData"
            @search="searchData"
          />
          <div class="tagging__sub-title mb-4">
            Address Labeling
          </div>
          <EthLabeling
            v-if="$can('use', 'eth')"
            ref="ethLabeling"
            :address-data="addressInfo"
            :address-pagination="addressPagination"
            :history="addressHistory"
            :loading="loading"
            :search="search"
            @change-address-page="changePage"
            @update-wallet="searchData"
          />
          <template v-else>
            <GeneralInfo
              class="mb-4"
              :data="generalInfo"
              :loading="loading"
            />
            <div class="flex mb-4">
              <div
                v-if="addressInfo.conflict"
                class="conflicts-marker__block"
                :class="{'mr-4': addressInfo.conflict && clusterInfo.conflict}"
              >
                <div>
                  There are unresolved labeling conflicts for this address
                </div>
                <gl-icon
                  class="pointer"
                  :height="24"
                  name="double-arrow-right"
                  :width="24"
                  @click="goToConflict('address', addressInfo.address)"
                />
              </div>
              <div
                v-if="clusterInfo.conflict"
                class="conflicts-marker__block"
              >
                <div>
                  There are unresolved labeling conflicts for this cluster
                </div>
                <gl-icon
                  class="pointer"
                  :height="24"
                  name="double-arrow-right"
                  :width="24"
                  @click="goToConflict('cluster', clusterInfo.cluster)"
                />
              </div>
            </div>

            <div class="flex mb-4 t-column">
              <AddressInfo
                :can-edit="Boolean(search && addressValid)"
                class="flex-1 mr-4 t-mr-0 t-mb-3"
                :data="addressInfo"
                :loading="loading"
                @remove-tag="removeTag"
                @save-address-data="updateAddressData"
              />
              <ClusterInfo
                :can-edit="Boolean(search && addressValid && generalInfo.clusterData && generalInfo.clusterData.clusterSize)"
                class="flex-1"
                :data="clusterInfo"
                :loading="loading"
                @remove-tag="removeCLusterTag"
                @save-cluster-data="updateClusterData"
              />
            </div>
            <AddressHistory
              class="mb-4"
              :data="addressHistory"
              :pagination="addressPagination"
              @change-page="changeAddressPage"
            />
            <ClusterHistory
              :data="clusterHistory"
              :pagination="clusterPagination"
              @change-page="changeClusterPage"
            />
          </template>
        </div>
      </div>
    </div>
    <HoldOnModal
      v-model="uploading"
      :file-name="uploadingFile.name"
      :progress="progress"
    />
    <confirmUpdateCalcModal
      v-if="showConfirmModal"
      v-model="showConfirmModal"
      :message="message"
      @close="closeConfirmHandler"
      @confirm="confirmHandler"
    />
  </GlPageWrap>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'
import GlSearchBox from '@/components/gl-search-box'
import GlCoinSelect from "@/components/gl-coin-select";
import GlPageWrap from "@/components/layout/gl-page-wrap";
import HoldOnModal from "@/pages/tagging/modals/HoldOnModal";
import GeneralInfo from "@/pages/tagging/components/GeneralInfo";
import AddressInfo from "@/pages/tagging/components/AddressInfo";
import ClusterInfo from "@/pages/tagging/components/ClusterInfo";
import AddressBulk from "@/pages/tagging/components/AddressBulk";
import ClusterBulk from "@/pages/tagging/components/ClusterBulk";
import EthLabeling from "@/pages/tagging/components/EthLabeling";
import AddressHistory from "@/pages/tagging/components/AddressHistory";
import ClusterHistory from "@/pages/tagging/components/ClusterHistory";
import ClusterConflicts from "@/pages/tagging/components/ClusterConflicts";
import AddressConflictsTab from "@/pages/tagging/components/AddressConflictsTab";
import ClustersConflictsTab from "@/pages/tagging/components/ClustersConflictsTab";
import confirmUpdateCalcModal from "@/pages/scoring/modals/confirmUpdateCalcModal";
// Vuex
import { mapActions, mapState, mapMutations } from "vuex"
// Utils
import {capitalizeFirstLetter, formatterRecalcApproveMessage} from "@/utils/text-formatter";
import { isValidEthAddress } from "@/utils/cytoskape-ui-rules";

// Libs
import { validate } from 'vee-validate'

export default {
  components: {
    GlIcon,
    GlPageWrap,
    GeneralInfo,
    AddressInfo,
    ClusterInfo,
    AddressBulk,
    ClusterBulk,
    HoldOnModal,
    GlSearchBox,
    EthLabeling,
    GlCoinSelect,
    AddressHistory,
    ClusterHistory,
    ClusterConflicts,
    AddressConflictsTab,
    ClustersConflictsTab,
    confirmUpdateCalcModal,
  },
  data() {
    return {
      search: '',
      skipCheck: false,
      showConfirmModal: false,
      message: '',
      preSaveData: {},
      addressValid: false,
      addressEthValid: false,
      uploading: false,
      loading: false,
      clusterDataById: {},
      progress: 0,
      conflictCounter: 0,
      conflictClusterCounter: 0,
      uploadingFile: '',
      conflictData: [],
      activeTab: 'labeling',
      generalInfo: {},
      clusterConflictsList: [],
      addressHistory: [],
      clusterHistory: [],
      addressInfo: {},
      clusterInfo: {},
      conflictType: 'address',
      clusterMergeConflictsTotal: 0,
      clusterMergeConflictsCount: 10,
      clusterMergeConflictsSkip: 0,
      addressConflictsFilters: {
        search: '',
        from: null,
        to: null,
      },
      clustersConflictsFilters: {
        search: '',
        from: null,
        to: null,
      },
      addressConflictsPagination: {
        currentPage: 1,
        perPage: 10,
        totalPages: 1,
        totalItems: 1,
      },
      clustersConflictsPagination: {
        currentPage: 1,
        perPage: 10,
        totalPages: 1,
        totalItems: 1,
      },
      addressPagination: {
        page: 1,
        total: 0,
        totalPages: 1,
        perPage: 10,
      },
      clusterPagination: {
        page: 1,
        total: 0,
        totalPages: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'currencyList', 'coinData'])
  },
  watch: {
    $route: {
      handler(val) {
        if (val.query.tab) {
          this.activeTab = val.query.tab

          if (this.$route.query.search) {
            this.search = this.$route.query.search;
            this.searchData(this.search, true)
          }

          if (this.activeTab === 'address-conflicts') {
            if (val.query.search) {
              this.addressConflictsFilters.search = val.query.search
            }
            this.loadConflicts()
            return
          }

          if (this.activeTab === 'cluster-conflicts') {
            if (val.query.search) {
              this.clustersConflictsFilters.search = val.query.search
            }
            this.loadConflicts()
            return
          }

          if (this.activeTab === 'cluster-merge-conflicts') {
            this.loadClusterConflicts()
            return
          }

          if (this.activeTab === 'bulk-labeling') {
            return
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.loadConflictsTotal('address')
    this.loadConflictsTotal('cluster')

    if (this.$route.query.type) {
      this.SET_COIN_TYPE(this.$route.query.type)

      const currency = this.currencyList.find(el => el.key === this.$route.query.type)

      this.SET_COIN_DATA(currency)
    }

    if (this.$route.query.address) {
      this.search = this.$route.query.address;
      this.searchData(this.search, false)
    }

    if (this.$route.query.cluster) {
      this.search = this.$route.query.cluster;
      this.searchData(this.search, true)
    }
  },
  methods: {
    ...mapMutations('analytics', ['SET_COIN_TYPE', 'SET_COIN_DATA']),
    ...mapActions('analytics', ['getAddressData', 'getAddressHistory', 'getClusterHistory', 'getEthAddressInfo']),
    ...mapActions('tagging', [
        'getAddressTags',
        'getClusterConflicts',
        'getClusterInfo',
        'getUploadConflicts',
        'resolveConflict',
        'deleteConflict',
        'getConflictsTotal',
        'generateClusterInfo'
    ]),
    isValidEthAddress,
    capitalizeFirstLetter,
    formatterRecalcApproveMessage,
    goToConflict(type = 'address', search = '') {
      this.$router.replace({ name: 'labeling', query: { tab: `${type}-conflicts`, search } }).catch(err => err)
    },
    enableCheckClusterConflicts() {
      this.generateClusterInfo().then(({ success }) => {
        if (success) {
          this.loadClusterConflicts()
        } else {
          this.$toasted.global.error({ message: 'Generate error' })
        }
      })
    },
    loadConflictsTotal(type) {
      this.getConflictsTotal({ type }).then(({ success, data }) => {
        if (success) {
          if (type === 'address') {
            this.conflictCounter = data.total
          } else if (type === 'cluster') {
            this.conflictClusterCounter = data.total
          }
        }
      })
    },
    handleActiveTab(name) {
     
      this.activeTab = name
      this.$router.replace({ name: 'labeling', query: { tab: this.activeTab } }).catch(err => err)
      if (this.activeTab === 'address-conflicts') {
        this.loadConflicts()
      }

      if (this.activeTab === 'cluster-conflicts') {
        this.loadConflicts()
      }

      if (this.activeTab === 'cluster-merge-conflicts') {
        this.loadClusterConflicts()
      }

      this.$nextTick(() => {
       this.$refs.tabsWrapper.scrollLeft = document.querySelector('.tab-active').offsetLeft - 10
      });
    
    },
    paginateClusterConflicts(page, perPage) {
        const sendData = {
            count: perPage,
            skip: (page - 1) * perPage,
        }

        this.clusterMergeConflictsCount = sendData.count
        this.clusterMergeConflictsSkip = sendData.skip

        this.loadClusterConflicts()
    },
    loadClusterConflicts() {
      this.loading = true
      this.getClusterConflicts({ count: this.clusterMergeConflictsCount, skip: this.clusterMergeConflictsSkip }).then(({ data: { list, total } }) => {
        this.clusterConflictsList = list
        this.clusterMergeConflictsTotal = total
      }).finally(() => this.loading = false)
    },
    loadConflicts() {
      const sendParams = this.formattedSendData()

      this.getUploadConflicts(sendParams).then(({ success, data }) => {
        if (success) {
          this.loadConflictsTotal('address')
          this.loadConflictsTotal('cluster')
          this.conflictData = data
        }
      })
    },
    formattedSendData() {
      if (this.activeTab === 'address-conflicts') {
        this.conflictType = 'address'
      }

      if (this.activeTab === 'cluster-conflicts') {
        this.conflictType = 'cluster'
      }

      if (this.conflictType === 'address') {
        return {
          address: this.addressConflictsFilters.search,
          from: this.addressConflictsFilters.from,
          to: this.addressConflictsFilters.to,
          count: this.addressConflictsPagination.perPage,
          type: this.conflictType,
          skip: (this.addressConflictsPagination.currentPage - 1) * this.addressConflictsPagination.perPage,
        }
      } else {
        return {
          cluster: this.clustersConflictsFilters.search,
          from: this.clustersConflictsFilters.from,
          to: this.clustersConflictsFilters.to,
          count: this.clustersConflictsPagination.perPage,
          type: this.conflictType,
          skip: (this.clustersConflictsPagination.currentPage - 1) * this.clustersConflictsPagination.perPage,
        }
      }
    },
    confirmHandler() {
      this.skipCheck = true
      this.addressApply(this.preSaveData.type, this.preSaveData.id, this.preSaveData.index)
    },
    closeConfirmHandler() {
      this.skipCheck = false
      this.showConfirmModal = false
    },
    addressApply(type, id, index) {
      this.preSaveData = {
        type,
        id,
        index
      }
      this.resolveConflict({ type, id, index, skipCheck: this.skipCheck }).then(({ success, data }) => {

        if (success) {
          this.$toasted.global.success({ message: 'Conflict resolved successfully!' })
          this.loadConflictsTotal(type)
          this.loadConflicts()
          this.showConfirmModal = false
        } else {
          if (data.showPopup || data?.response?.messageData) {
            this.message = this.formatterRecalcApproveMessage ? this.formatterRecalcApproveMessage(data?.response?.messageData) : data.message
            this.showConfirmModal = true
          } else this.$toasted.global.error({ message: `${data.message}`})
        }
      })
    },
    addressKeep(type, id) {
      this.deleteConflict({ type, id }).then(({ success }) => {
        if (success) {
          this.$toasted.global.success({ message: 'Conflict resolved successfully!' })
          this.loadConflictsTotal(type)
          this.loadConflicts()
        }
      })
    },
    changePage(page) {
      this.addressPagination.page = page
      this.searchData(this.search)
    },
    handleChangeCoin() {
      this.activeTab = 'labeling'
      this.resetData()
    },
    async searchData(search, fromClusterData = false) {
      if (!fromClusterData) {
        this.$router.replace({ name: 'labeling', query: { ...this.$route.query, address: search, type: this.coinType } }).catch(err => err)
      } else {
        this.$router.replace({ name: 'labeling', query: { ...this.$route.query, cluster: this.clusterDataById.cluster, type: this.coinType } }).catch(err => err)
      }

      if (this.coinData.family === 'eth' && this.isValidEthAddress(search)) {
        this.addressEthValid = true
        this.loading = true
        await this.getEthAddressInfo(search).then(({ data, success }) => {
          if (success) {
            this.generalInfo = { ...data, address: data.address || ''  };
            this.setAddressInfo(data);
            this.getAddressHistory({ address: search, page: this.addressPagination.page }).then(({ data }) => {
              this.addressHistory = data.logs ? data.logs : []
              this.addressPagination = {
                page: Number(data.page),
                total: data.totalItems,
                perPage: data.itemsPerPage,
                totalPages: data.totalPages,
              }
            });
          } else {
            this.resetData()
            this.addressInfo.address = this.search
          }
        }).finally(() => {
          this.loading = false
        });
        return
      } else if (this.coinData.family === 'eth'){
        this.addressEthValid = false
        this.$toasted.global.error({ message: `Value is not valid`})
        return
      }

      this.addressEthValid = false

      const addressValidationResult = await validate(String(search), `address:${this.coinData.key}`, { name: 'Search value' })
        if (addressValidationResult.valid) {
          this.addressValid = true
          this.loading = true

          await this.getAddressData(search).then(({ data, success }) => {
            if (success) {
              this.generalInfo = { ...data, address: data.address || (data.clusterData && data.clusterData.address) || ''  };
              this.setAddressInfo(data);
              this.setClusterInfo(data);
              this.getAddressHistory({ address: search, page: this.addressPagination.page }).then(({ data }) => {
                this.addressHistory = data.logs
                this.addressPagination = {
                  page: Number(data.page),
                  total: data.totalItems,
                  perPage: data.itemsPerPage,
                  totalPages: data.totalPages,
                }
              });
            } else {
              this.resetData()
              this.clusterInfo.address = this.search
              this.addressInfo.address = this.search
            }
            if (this.generalInfo.cluster) {
              this.getClusterHistory({ cluster: this.generalInfo.cluster, page: this.clusterPagination.page }).then(({ data }) => {
                this.clusterHistory = data.logs
                this.clusterPagination = {
                  page: Number(data.page),
                  total: data.totalItems,
                  perPage: data.itemsPerPage,
                  totalPages: data.totalPages,
                }
              });
            } else {
              this.clusterHistory = []
              this.clusterPagination = {}
            }
          }).finally(() => {
            this.loading = false
          });
          await this.getAddressTags(search).then(({ data }) => {
            this.addressInfo.tags = data.tags
          })
          return
        } else if ( Number(search) ) {
          this.getClusterInfo(search).then(({ data, success }) => {
            if (success) {
              this.clusterDataById = data
              this.searchData(this.clusterDataById.address, true)
            } else {
              this.$toasted.global.error({ message: `${data.message}`})
              this.resetData()
            }
          }).catch(({response: {data}}) => {
            if (data.data.message) {
              this.$toasted.global.error({message: `${data.data.message}`})
            }
          })
        } else this.$toasted.global.error({ message: `Value is not valid`})

        this.addressValid = false
      },
      completeUpload() {
        this.uploading = false;
        this.loadConflictsTotal('address')
        this.loadConflictsTotal('cluster')
        if (this.search) {
          this.searchData(this.search)
        }
      },
      removeTag(tag) {
        const removeIndex = this.addressInfo.tags.findIndex(({ name }) => name === tag)
        this.addressInfo.tags.splice(removeIndex, 1)
        this.searchData(this.search)
      },
      removeCLusterTag(tag) {
        const removeIndex = this.clusterInfo.tags.findIndex(({ name }) => name === tag)
        this.clusterInfo.tags.splice(removeIndex, 1)
        this.searchData(this.search)
      },
      formattedAddressHistory() {
        this.getAddressHistory({ address: this.search, page: this.addressPagination.page }).then(({ data }) => {
          this.addressHistory = data.logs
          this.addressPagination = {
            page: Number(data.page),
            total: data.totalItems,
            perPage: data.itemsPerPage,
            totalPages: data.totalPages,
          }
        });
      },
      clearFilters() {
        this.addressConflictsFilters.from = null
        this.addressConflictsFilters.to = null
        this.addressConflictsFilters.search = ''
        this.clustersConflictsFilters.from = null
        this.clustersConflictsFilters.to = null
        this.clustersConflictsFilters.search = ''
        this.loadConflicts()
      },
      setDateClusterConflicts({ from, to }) {
        this.clustersConflictsFilters.from = from
        this.clustersConflictsFilters.to = to
        this.loadConflicts()
      },
      clusterConflictsSearch(val) {
        this.clustersConflictsFilters.search = val
        this.loadConflicts()
      },
      setDateAddressConflicts({ from, to }) {
        this.addressConflictsFilters.from = from
        this.addressConflictsFilters.to = to
        this.loadConflicts()
      },
      addressConflictsSearch(val) {
        this.addressConflictsFilters.search = val
        this.loadConflicts()
      },
      changeAddressPage(page) {
        this.addressPagination.page = page;
        this.formattedAddressHistory();
      },
      changeAdrConflictsPage(page) {
        this.addressConflictsPagination.currentPage = page
        this.loadConflicts()
      },
      changeAdrConflictsCount(count) {
        this.addressConflictsPagination.perPage = count
        this.loadConflicts()
      },
      changeClusterConflictsPage(page) {
        this.clustersConflictsPagination.currentPage = page
        this.loadConflicts()
      },
      changeClusterConflictsCount(count) {
        this.clustersConflictsPagination.perPage = count
        this.loadConflicts()
      },
      formattedClusterHistory() {
        if (this.generalInfo.cluster) {
          this.getClusterHistory({ cluster: this.generalInfo.cluster, page: this.clusterPagination.page }).then(({ data }) => {
            this.clusterHistory = data.logs
            this.clusterPagination = {
              page: Number(data.page),
              total: data.totalItems,
              perPage: data.itemsPerPage,
              totalPages: data.totalPages,
            }
          });
        }
      },
      changeClusterPage(page) {
        this.clusterPagination.page = page;
        this.formattedClusterHistory();
      },
      handleUploading(progress, file) {
        this.uploading = true
        this.progress = progress
        this.uploadingFile = file
      },
      setAddressInfo(data) {
        this.addressInfo = {
          address: data.address || (data.clusterData && data.clusterData.address) || this.search || '',
          type: data.type ? { ...data.type, name: capitalizeFirstLetter(data.type.name) } : '',
          owner: data.owner || '',
          conflict: data.conflict,
          meta: data.meta || [],
          description: data.description || '',
          riskScore: data.riskScore || null,
          tags: data.tags && data.tags.length ? data.tags.map(tag => ({
            ...tag,
            name: capitalizeFirstLetter(tag.name),
            metadata: data.meta.find(item => item.tagId === tag._id)
              ? data.meta.find(item => item.tagId === tag._id).metadata
              : '',
          })) : [],
        }
      },
      setClusterInfo(data) {
        this.clusterInfo = {
          cluster: data.cluster || null,
          owner: data.clusterData && data.clusterData.owner || '',
          meta: data?.clusterData?.meta || [],
          tags: data?.clusterData?.tags && data.clusterData.tags.length ? data.clusterData.tags.map(tag => ({
            ...tag,
            name: capitalizeFirstLetter(tag.name),
            metadata: data.clusterData.meta.find(item => item.tagId === tag._id)
                ? data.clusterData.meta.find(item => item.tagId === tag._id).metadata
                : '',
          })) : [],
          conflict: data.clusterData && data.clusterData.conflict,
          type: data?.clusterData?.type ? { ...data?.clusterData?.type, name: capitalizeFirstLetter(data?.clusterData?.type.name) } : '',
          description: data?.clusterData && data.clusterData.description || '',
          riskScore: data?.clusterData && data.clusterData.riskScore || null,
          address: data.address || this.addressInfo.address,
        }
      },
      resetData() {
        this.generalInfo = {};
        this.addressInfo = {};
        this.clusterInfo = {};
        this.addressHistory = [];
        this.clusterHistory = [];
        this.addressPagination = {
          page: 1,
          total: 0,
          totalPages: 1,
          perPage: 10,
        };
        this.clusterPagination = {
          page: 1,
          total: 0,
          totalPages: 1,
          perPage: 10,
        };
        this.$router.replace('labeling')
      },
      updateAddressData(data) {
        this.addressInfo = data;
        this.loadConflictsTotal('address')
        this.searchData(this.search)
      },
      updateClusterData(data) {
        this.clusterInfo = data;
        this.loadConflictsTotal('cluster')
        this.searchData(this.search)
      },
    },
}
</script>

<style scoped>
.page-title-tab {
  font-size: 18px;
}

.labeling-tabs-wrap {
  white-space: nowrap;
  flex: 1;
  margin-right: 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tab {
  position: relative;
}

.tab-counter {
  height: 20px;
  min-width: 20px;
  border-radius: 20px;
  background: var(--scarlet-red);
  color: #fff;
  position: absolute;
  top: -15px;
  right: -15px;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1px 4px;
}

.conflicts-marker__block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  border-radius: 3px;
  background: var(--pale-pink);
  padding: 10px;
}
</style>

<style>
@media (max-width: 1024px) {
  .tagging .o-pag--mobile .o-pag__previous, .tagging .o-pag--mobile .o-pag__next {
    flex-grow: unset;
  }
}
</style>
